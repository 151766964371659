import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { Perks, PerkItem, PerkImage, PerkText } from ".";
import { Anchor, ContactsContainer, Slogan } from "../components/Banner";
import { Contacts } from "../components/Contacts";
import { Layout } from "../components/Layout";
import { breakpoints } from "../styledResponsive";

const Banner = styled.div`
    width: 100%;
    position: relative;
`;

export const Content = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Text = styled.p`
    font-size: 18px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.alternative};
    width: 90%;
    text-align: center;
    ${breakpoints("width", ["90%", "90%", "50%", "40%"])};
    font-weight: 100;
    margin: auto;
    margin-top: 20px;
`;

const BannerTextContainer = styled.div`
    display: none;
    ${breakpoints("width", ["none", "block", "block", "block"])};
`;

export const ImageDesktopContainer = styled.div`
    display: none;
    ${breakpoints("display", ["none", "none", "none", "block"])};
`;

export const ImageMobileContainer = styled.div`
    display: block;
    ${breakpoints("display", ["block", "block", "none", "none"])};
`;

export const ImageLaptopContainer = styled.div`
    display: none;
    ${breakpoints("display", ["none", "none", "block", "none"])};
`;

const TerroirPerks = styled(Perks)`
    margin-top: 40px;
`;

export const BannerTextContainerDesktop = styled.div`
    display: none;
    ${breakpoints("display", ["none", "none", "none", "block"])};
`;

export const TextContainerLaptop = styled.div`
    display: block;
    ${breakpoints("display", ["block", "block", "block", "none"])};
    margin-top: 40px;
`;

export const IntroText = styled(Text)`
    color: #444;
`;

const Vale = () => {
    return (
        <Layout>
            <Banner>
                <ContactsContainer>
                    <Contacts />
                </ContactsContainer>
                <ImageDesktopContainer>
                    <StaticImage
                        src="../images/banner-vale.jpg"
                        alt="Vale dos Vinhedos"
                    />
                </ImageDesktopContainer>
                <ImageMobileContainer>
                    <StaticImage
                        src="../images/banner-vale-mobile.jpg"
                        alt="Vale dos Vinhedos"
                    />
                </ImageMobileContainer>
                <ImageLaptopContainer>
                    <StaticImage
                        src="../images/banner-vale-laptop.jpg"
                        alt="Vale dos Vinhedos"
                    />
                </ImageLaptopContainer>
                <Content>
                    <Slogan>
                        Vale dos Vinhedos,
                        <br />
                        Serra Gaúcha,
                        <br />
                        Um lugar único.
                    </Slogan>
                    <BannerTextContainerDesktop>
                        <Text>
                            São muitas as razões para apontar o Vale dos
                            Vinhedos como um lugar sem igual. A começar pela sua
                            paisagem espetacular, repleta de parreirais a perder
                            de vista. Outra característica marcante é a presença
                            lado a lado de um grande número de vinícolas, em sua
                            grande maioria de qualidade internacional.
                        </Text>
                        <Text>
                            Suas lindas e tradicionais cantinas ficam abertas ao
                            público para visitação e degustação de vinhos e
                            espumantes. Destaca-se também a herança cultural da
                            imigração italiana, presente nas construções, na
                            comida e, é claro, na arte de elaborar o vinho
                        </Text>
                        <Text>
                            O Vale dos Vinhedos reúne a beleza natural da serra
                            gaúcha com uma certa atmosfera europeia que resulta
                            em uma combinação que não se encontra em nenhum
                            outro lugar do Brasil. Por isso, podemos afirmar que
                            o Vale dos Vinhedos é um lugar essencialmente único.
                        </Text>
                    </BannerTextContainerDesktop>
                    <Anchor href="#main-content">
                        <StaticImage
                            src="../images/icons/arrow-down.png"
                            alt="Ver conteúdo"
                            style={{
                                maxWidth: "100%",
                                display: "block",
                            }}
                        />
                    </Anchor>
                </Content>
            </Banner>
            <TextContainerLaptop>
                <IntroText>
                    São muitas as razões para apontar o Vale dos Vinhedos como
                    um lugar sem igual. A começar pela sua paisagem espetacular,
                    repleta de parreirais a perder de vista. Outra
                    característica marcante é a presença lado a lado de um
                    grande número de vinícolas, em sua grande maioria de
                    qualidade internacional.
                </IntroText>
                <IntroText>
                    Suas lindas e tradicionais cantinas ficam abertas ao público
                    para visitação e degustação de vinhos e espumantes.
                    Destaca-se também a herança cultural da imigração italiana,
                    presente nas construções, na comida e, é claro, na arte de
                    elaborar o vinho.
                </IntroText>
                <IntroText>
                    O Vale dos Vinhedos reúne a beleza natural da serra gaúcha
                    com uma certa atmosfera européia que resulta em uma
                    combinação que não se encontra em nenhum outro lugar do
                    Brasil. Por isso, podemos afirmar que o Vale dos Vinhedos é
                    um lugar essencialmente único.
                </IntroText>
            </TextContainerLaptop>
            <TerroirPerks id="main-content">
                <PerkItem>
                    <PerkImage>
                        <StaticImage
                            src="../images/vale/img-1.jpg"
                            alt="Infraestrutura de segurança com condomínio
totalmente murado e portaria 24 horas"
                        />
                    </PerkImage>
                </PerkItem>
                <PerkItem>
                    <PerkImage>
                        <StaticImage
                            src="../images/vale/img-2.jpg"
                            alt="Infraestrutura de segurança com condomínio
totalmente murado e portaria 24 horas"
                        />
                    </PerkImage>
                </PerkItem>
                <PerkItem>
                    <PerkImage>
                        <StaticImage
                            src="../images/vale/img-3.jpg"
                            alt="Infraestrutura de segurança com condomínio
    totalmente murado e portaria 24 horas"
                        />
                    </PerkImage>
                </PerkItem>
                <PerkItem>
                    <PerkImage>
                        <StaticImage
                            src="../images/vale/img-4.jpg"
                            alt="Infraestrutura de segurança com condomínio
totalmente murado e portaria 24 horas"
                        />
                    </PerkImage>
                </PerkItem>
            </TerroirPerks>
        </Layout>
    );
};

export default Vale;
